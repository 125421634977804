.card {
    color: var(--background);
    background-color: var(--card-color);
    border: 3px solid var(--secondary);
    text-align: left;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 90%;
    height: auto;
    margin: auto;
    min-width: 150px;
    overflow: hidden;
}