@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.block {
    color: "black";
    background-color: var(--block-color);
    /* border: 12px solid var(--accent); */
    margin: auto;
    text-align: left;
    width: auto;
    box-sizing: border-box;
    padding: 30px;
}

.css-16ut1rm {

    font-family: 'Josefin Sans' !important;
    color: white !important;
    font-size: 1.5rem !important;

}

.css-7p547w {
    font-family: 'Josefin Sans' !important;
    font-size: 1.5rem !important;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    font-family: 'Josefin Sans' !important;
    font-size: 1.5rem !important;
}

.css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
    font-family: 'Josefin Sans' !important;
    color: white !important;
    font-size: 1.5rem !important;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    padding: 0rem;
}