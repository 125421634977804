@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.flex-row-container {
  display: 'flex';
  flex-direction: 'row';
  align-items: 'flex-start';
}

.flex-row-container>item {
  align-self: 'center';
  width: 100%;
}

.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-family: 'Josefin Sans';
  font-size: large;
  font-weight: bold;
  /* padding: 20px; */
  width: 100vw;
  transition: all .5s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
}

svg-container {
  resize: both;
  min-height: 500px;
  display: flex;
  height: 25%;
  width: 25%;
}

svg {
  width: 100%;
  display: block;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

svg:hover {
  stroke-width: 0.5;
}


svg g {
  fill: var(--background);
  fill-opacity: 1;
}

svg g:hover {
  stroke-width: 0.5;
  /* fill: #7be3f6;
  fill-opacity: 1; */
}

svg g:hover text {
  fill-opacity: 1;
}

svg g polygon {
  stroke: #c7b706;
  /* stroke-width: 0.2; */
  /* transition: fill-opacity .5s; */
}

svg g polygon:hover {
  stroke-width: 1;
}

svg path {
  fill: var(--dark-brown);
  stroke: var(--dark-brown);
  stroke-width: 0.2em;
  stroke-opacity: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.project_details {
  position: absolute;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 150px;
  left: 0%;
  z-index: 999;
  background: white;
  padding: 10px;
  border: 1px solid grey;
  box-shadow: 0 0 2px 2px grey;
}

.project_details__wrapper {
  display: flex;
  align-content: center;
  justify-content: center;

}

span:not(:first-of-type):before {
  padding-left: 2rem;
  white-space: pre;
  content: "|         ";
}

span:empty:before {
  content: "";
}


hr {
  width: 90%;
  stroke-width: 5rem;
  align-content: center;
  color: var(--dark-brown);
  margin: auto;
}

.fa-icon {
  width: 500;
  height: 500;
}

.icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 90%;
  padding: 0px;
  margin: auto;
  /* min-width: 300px; */
}

.project-card-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  /* flex-shrink: 1 0 auto; */
}

/* 
.one {
  flex: 1 1 0;
}

.two {
  flex: 1 1 0;
}

.three {
  flex: 2 1 0;
} */

/* .project-card-content>* {
  flex: 1 auto 0;
} */

.hexgrid {
  flex: 1 auto 0;
  min-width: 350px;
}

.timeline {
  flex: 1 auto 0;
  padding: 1rem;
}

.project-details {
  flex: 1 auto 0;
  padding: 1rem;
}